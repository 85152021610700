import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to "chat-messages"
export default class extends Controller {
  connect() {
    this.addUnreadLine();
    this.scrollDown();
    this.markRead();
  }

  scrollDown() {
    $('#messages').css('scroll-behavior', 'auto');
    $('#messages')[0].scrollTop = $('#messages')[0].scrollHeight;
    $('#messages').css('scroll-behavior', 'smooth');
  }

  markRead() {
    Rails.ajax({
			url: `/chats/${this.getID()}/clear_unread_messages`,
			type: 'post'
		});
  }

  addUnreadLine() {
    var messages = document.getElementsByClassName("message-row");
    var unread = this.getUnread();

    if ((unread > 0) && (messages.length >= unread)) {
        var hr = document.createElement("HR");
        hr.setAttribute("id", "unread-seperator");
        hr.classList.add('border', 'border-secondary', 'border-1', 'opacity-50', 'mb-4');
        var div1 = document.createElement("DIV");
        div1.classList.add('position-relative', 'mb-5');
        var div2 = document.createElement("DIV");
        div2.classList.add('position-absolute', 'top-0', 'start-50', 'translate-middle');
        var h = document.createElement("h6");
        h.classList.add("pt-3", "pb-4");
        var text = document.createTextNode("* Unread Messages *");
        h.appendChild(text);
        div2.appendChild(h);
        div1.appendChild(div2);
        var insertIndex = messages.length - unread;
        if (insertIndex > 0) {
            messages[insertIndex - 1].parentNode.insertBefore(hr, messages[insertIndex - 1].nextSibling);
        } else {
            messages[0].parentNode.insertBefore(hr, messages[0]);
        }
        var seperator = document.getElementById("unread-seperator");
        seperator.parentNode.insertBefore(div1, seperator.nextSibling);
    }
  }


  onScroll(){
    if ($('#messages').scrollTop() === 0) {
      this.loadMoreMsgs();
    }
  }

  loadMoreMsgs(){
    let next_page = document.getElementById('messages-pagination').querySelector("a[rel='next']")
    if (next_page == null) { return }
    next_page.click();
  }

  getID() {
		return this.data.get('id');
	}

  getUnread() {
		return this.data.get('unread');
	}
}
